import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./404.css"

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO
        title="Page Not Found"
        description="You've stepped out the airlock"
      />

      <div className="space">
        <center>
          <h1 className="mb-6">It looks like you've been spaced...</h1>
          <img
            src="./clown.gif"
            className="inline-block spin"
            alt="Clown lost in space"
          />
          <p className="mt-6">
            Get back to the <Link to="/">station</Link>!
          </p>
        </center>
      </div>
    </Layout>
  )
}
